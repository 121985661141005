import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styles from './blog.module.css'
import Layout from "../components/layout"
import ArticlePreview from '../components/article-preview'
import heroStyles from '../components/hero.module.css'
import Img from 'gatsby-image'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [headerImage] = get(this, 'props.data.allContentfulAsset.edges')

    return (
      <Layout location={this.props.location} >
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} defer={false}>
            {typeof window !== "undefined" && (
              <script async>
                {(function(s, u, m, o, j, v) {
                  j = u.createElement(m);
                  v = u.getElementsByTagName(m)[0];
                  j.async = 1;
                  j.src = o;
                  j.dataset.sumoSiteId =
                    "7e060cadcf05e6c28496d4ed37553b128b7e8a4acd6bf9ac5ab0358e2c235be0";
                  v.parentNode.insertBefore(j, v);
                })(window, document, "script", "//load.sumo.com/")}
              </script>
            )}
          </Helmet>
          <div className={heroStyles.hero}>
            <Img className={heroStyles.heroImage} alt={headerImage.node.title} fluid={headerImage.node.fluid} />
          </div>
          <div className="wrapper">
            <h2 className="section-headline">Recent articles</h2>
            <ul className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulAsset(filter: {contentful_id: {eq: "460kOoKp2TJsDqofCdF1K"}}) {
      edges {
        node {
          title
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`
